import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useMediaQuery } from 'react-responsive';
import React,{ useState, useEffect }  from 'react';
import { MdOutlineHighQuality,MdOutlineViewModule,MdPersonalInjury,MdOutlineAppSettingsAlt,MdDataset,MdModelTraining,MdOutlineOnlinePrediction } from "react-icons/md";
import { CWidgetStatsF,CFormSelect,CCard,CCardImage,CCardBody,CCardTitle,CCardText,CFormCheck,CAvatar,CFooter,CLink,CWidgetStatsA,CCarouselCaption,CCarousel,CCarouselItem,CDropdownDivider,CListGroupItem,CListGroup,CButton,CBadge,CInputGroupText,CFormInput,CInputGroup,CImage,CDropdownToggle,CDropdownItem,CDropdownMenu,CDropdown,CRow,CCol,CContainer,CNavLink,CNav,CHeader,CHeaderBrand } from '@coreui/react';
import { Link } from "react-router-dom";
import { FaBiohazard } from "react-icons/fa";
import { TbPhysotherapist,TbMassage,TbHealthRecognition } from "react-icons/tb";
import { TiSocialFacebookCircular,TiSocialInstagramCircular,TiSocialLinkedinCircular } from "react-icons/ti";
import {RiTwitterXFill,RiYoutubeLine,RiPsychotherapyLine} from "react-icons/ri";
import { GiHealthPotion ,GiSkeleton,GiAmbulance,GiPlayerTime,GiMechanicalArm } from "react-icons/gi";
import { FaNutritionix,FaTrashAlt,FaMapMarkerAlt,FaWhatsapp,FaChevronCircleRight,FaAutoprefixer } from "react-icons/fa";
import { FcAbout } from "react-icons/fc";
import { BsFillProjectorFill } from "react-icons/bs";
import { SiGooglelens } from "react-icons/si";
import { VscCircuitBoard,VscBeaker  } from "react-icons/vsc";
import { CgGym } from "react-icons/cg";
function Home() {
    const [isLoaded, setIsLoaded] = useState(false);
    const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps
    
    useEffect(() => {
      
        setIsLoaded(true);
    }, []);
    
    useEffect(() => {//on load
        if (isLoaded) {
           var fm=new Date();
           var xm=fm.getFullYear();
           document.getElementById("cprt_yr").innerText=xm;
        }
        },[isLoaded]);
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 999px)'
      })
     
      const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
      const isTabletOrMobile = useMediaQuery({ query: '(max-width: 998.9px) and (min-width:600px)' })
      const isMobile = useMediaQuery({ query: '(max-width: 599px)' })
      const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
      const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
      const wlpr={
          padding:"2%",
          height:'auto',
          width:'100%',
          boxShadow:'rgba(0, 0, 0, 0.2) 0px 20px 30px 0px',
          background:'linear-gradient(45deg, #0f0c29, #302b63, #24243e)'
        }
      const hdr={
          padding:'1%',
          height:'5em',
          width:'100%',
          boxShadow:'rgba(0, 0, 0, 0.45) 0px 25px 20px -20px',
          background:'linear-gradient(45deg, #0d0d0d, #2a0038, #2a0038,  #0d0d0d)'
      }
      const hdr_mob={
        padding:'1%',
        height:'6em',
        width:'100%',
        boxShadow:'rgba(0, 0, 0, 0.45) 0px 25px 20px -20px',
        background:'linear-gradient(45deg, #0d0d0d, #2a0038, #2a0038,  #0d0d0d)'
    }
      const hdr_tab={
        padding:'1%',
        height:'5em',
        width:'100%',
        boxShadow:'rgba(0, 0, 0, 0.45) 0px 25px 20px -20px',
        background:'linear-gradient(45deg, #0d0d0d, #2a0038, #2a0038,  #0d0d0d)'
    }
      const ftr={
        padding:'1%',
        height:'3em',
        width:'100%',
        boxShadow:'rgba(0, 0, 0, 0.45) 0px 25px 20px -20px',
        background:'linear-gradient(45deg, #0d0d0d, #2a0038, #2a0038,  #0d0d0d)',
        position:'fixed',
        bottom:'0.4%',
        color:'#b3ffff'
      }
      const hdr_sochl={
         fontSize:'1.5em',
         color:'white'
      }
      const hdr_sochl1={
        fontSize:'0.95em',
        color:'white'
     }
      const hdr_sochl_otr={
          textAlign:'right'
      }
      const cmpny_stl={
          textAlign:'center',
          fontSize:'1.2em',
          color:'#b3ffff',
          fontFamily:'Merriweather'
      }
      const tg_ln={
          textAlign:'center',
          fontSize:'0.90em',
          fontFamily:'Montserrat',
          color:'#b3ffff'
      }
      const tg_lnz={
        textAlign:'center',
        fontSize:'0.80em',
        fontFamily:'Montserrat',
        color:'#b3ffff',
        background:'linear-gradient(45deg, #0f0c29, #302b63, #24243e)'
    }
      const cmpny_stl1={
        textAlign:'center',
        fontSize:'0.80em',
        color:'#b3ffff',
        fontFamily:'Merriweather'
    }
    const tg_ln1={
        textAlign:'center',
        fontSize:'0.70em',
        fontFamily:'Montserrat',
        color:'#b3ffff'
    }
      const hdr_img={
          textAlign:'center'
      }
      const wlppr={
        width:'600px',
        height:'500px',
          paddingLeft:'22%',
          paddingTop:'1%'
      }
      const wlppr1={
        width:'400px',
        height:'400px',
          paddingLeft:'1%',
          paddingTop:'1%'
      }
      const ghj={
      
        width:'100%',
        height:'auto',
       
       }
       const dgg={
           textAlign:'center',
        color:'#b3ffff',
         fontWeight:'600',
         fontSize:'1.5em'
       }
       const dggn={
        textAlign:'left',
     color:'#b3ffff',
      fontWeight:'600',
      fontSize:'1.5em'
    }
    return (
        <div className="home">
     {isDesktopOrLaptop && (
         <>
         <CContainer fluid style={hdr}>
          <CRow>
              <CCol lg={3} xl={3} xxl={3} style={hdr_img}>
              <CImage fluid rounded src="https://lifelinehealth.in/bmw/logo1.png" width={'40em'} height={'40em'} />
              </CCol>
              <CCol lg={6} xl={6} xxl={6}>
              <CRow>
              <CCol lg={3} xl={3} xxl={3} style={tg_ln}>
              Empowering Health Through Technology.
              </CCol>
              <CCol lg={6} xl={6} xxl={6} style={cmpny_stl}>
                HINDCARE MEDTEC PVT. LTD.
              </CCol>
              <CCol lg={3} xl={3} xxl={3} style={tg_ln}>
              Smart Tech, Smarter Healthcare.
              </CCol>
              </CRow>
              </CCol>
              <CCol lg={3} xl={3} xxl={3} style={hdr_sochl_otr}>
                <CRow>
                <CCol lg={3} xl={3} xxl={3} style={hdr_sochl}><TiSocialInstagramCircular/></CCol>
                <CCol lg={3} xl={3} xxl={3} style={hdr_sochl}><TiSocialLinkedinCircular/></CCol>
                <CCol lg={3} xl={3} xxl={3} style={hdr_sochl}><RiTwitterXFill/></CCol>
                <CCol lg={3} xl={3} xxl={3} style={hdr_sochl}><RiYoutubeLine /></CCol>
                </CRow>
              </CCol>
          </CRow>
         </CContainer>
         <CContainer fluid style={wlpr}>
         <CCarousel controls transition="crossfade">
  <CCarouselItem>
  <CRow>
  <CCol lg={8} xl={8} xxl={8}>
    <CImage fluid rounded className="d-inline w-100" style={wlppr} src={"http://localhost/HindCare/Image/ambulance.png"} alt="Ambulance" />
    </CCol>
    <CCol lg={3} xl={3} xxl={3} style={{textAlign:'left',paddingTop:'12%',fonWeight:'600',fontFamily:'Montserrat',fontSize:'1.2em'}}>
    <CWidgetStatsF style={{background:'linear-gradient(45deg, #0d0d0d, #2a0038, #2a0038,  #0d0d0d)',color:'#b3ffff'}}
        className="mb-3"
        value="Lifeline App to Book ambulance and hospital beds through our extensive network"
       />

         </CCol>
         </CRow>
  </CCarouselItem>
  <CCarouselItem>
  <CRow>
  <CCol lg={8} xl={8} xxl={8}>
    <CImage fluid rounded className="d-inline w-100"  style={wlppr}   src={"http://localhost/HindCare/Image/smart_bin.jpg"} alt="garbage_bin" />
    </CCol>
    <CCol lg={3} xl={3} xxl={3} style={{textAlign:'left',paddingTop:'12%',fonWeight:'600',fontFamily:'Montserrat',fontSize:'1.2em'}}>
    <CWidgetStatsF style={{background:'linear-gradient(45deg, #0d0d0d, #2a0038, #2a0038,  #0d0d0d)',color:'#b3ffff'}}
        className="mb-3"
        value="BMWSGB(Bio Medical Waste Smart Garbage Bin)"
       />
         </CCol>
    </CRow>
  </CCarouselItem>
  <CCarouselItem>
  <CRow>
  <CCol lg={8} xl={8} xxl={8}>
    <CImage fluid rounded className="d-inline w-100"   style={wlppr}   src={"http://localhost/HindCare/Image/dash.jfif"} alt="garbage_bin" />
    </CCol>
    <CCol lg={3} xl={3} xxl={3} style={{textAlign:'left',paddingTop:'12%',fonWeight:'600',fontFamily:'Montserrat',fontSize:'1.2em'}}>
    <CWidgetStatsF style={{background:'linear-gradient(45deg, #0d0d0d, #2a0038, #2a0038,  #0d0d0d)',color:'#b3ffff'}}
        className="mb-3"
        value="AI powered Dash Cam device with crash detection feature(CDDCS)"
       />
         </CCol>
    </CRow>
  </CCarouselItem>
  <CCarouselItem>
  <CRow>
  <CCol lg={8} xl={8} xxl={8}>
    <CImage fluid rounded className="d-inline w-100"  style={wlppr}   src={"http://localhost/HindCare/Image/sports.png"} alt="garbage_bin" />
    </CCol>
    <CCol lg={3} xl={3} xxl={3} style={{textAlign:'left',paddingTop:'12%',fonWeight:'600',fontFamily:'Montserrat',fontSize:'1.2em'}}>
    <CWidgetStatsF style={{background:'linear-gradient(45deg, #0d0d0d, #2a0038, #2a0038,  #0d0d0d)',color:'#b3ffff'}}
        className="mb-3"
        value="Sports Science(In depth analysis about the key athletic parameters)"
       />
         </CCol>
    </CRow>
  </CCarouselItem>

</CCarousel>

</CContainer>
<CContainer fluid style={wlpr}>
<CRow>
<CCol lg={12} xl={12} xxl={12} style={{textAlign:'left',fonWeight:'500',fontFamily:'Merriweather',fontSize:'1.0em',color:'#b3ffff'}}>
<CCard style={{ width: '100%',padding:'1%',background:'linear-gradient(45deg, #0f0c29, #302b63, #24243e)' }}>
  <CCardImage orientation="top" style={ghj} />
  <CCardBody style={{color:'#b3ffff'}}>
    <CCardTitle style={dgg}><BsFillProjectorFill/>Projects</CCardTitle>
    <CCardText style={{textAlign:'left',fontSize:'1.3em',fontStyle:'italic'}}> 
     Here are some details into our projects!!
    </CCardText>
    
  </CCardBody>
  <CCardBody style={{color:'#b3ffff'}}>
    <CCardTitle style={dggn}><SiGooglelens/>CDDCS</CCardTitle>
    <CCardText style={{textAlign:'left',fontSize:'1.3em'}}> 
    CDDCS(Crash Detection Dash Cam System) :-for automobiles which will enable any 
user with the  device installed on their vehicle will have access to an ambulance in case 
the vehicle meets with any accident without  any manual intervention. 
Currently we have developed the crash detection prototype and in process of designing a 
3d model for testing the device on a ramp. 
Also the camera is AI trained to detect the degree of emergency required in case of  
accident , the inormation and image can be used to synchronize ambulance, hospitals to  
handle the case in  most efficient way.
<br/>
Heres a snapshot of our device data
<br/>
<CImage fluid rounded src="http://localhost/HindCare/Image/cdds.png" />
   <br/>
   Once the prototype meets the industry standards, we will convert the device into a <VscCircuitBoard/>(PCB).
    </CCardText>
    
  </CCardBody>
  <CCardBody style={{color:'#b3ffff'}}>
    <CCardTitle style={dggn}><FaTrashAlt/>BMWSGB</CCardTitle>
    <CCardText style={{textAlign:'center',fontSize:'1.3em'}}> 
    BMWSGB (Bio Medical Waste Smart Garbage Bin) :-  The medical facilities have a big 
challenge of segregating medical waste. On an average  a 100 bedded hospital generates 
100-200kg of  waste per day.To tackle this problem we our developing a smart BMW  
garbage bin 
Our team is  training  the system  currently on various sample of medical waste using 
image recognition ,the process of training  the system can take 4-8months,since the list of 
medical waste is huge. 
The physical  design of the  bin is also being explored as well simultaneously .
<br/>
<CImage fluid rounded src="http://localhost/HindCare/Image/bmw.png" />
    </CCardText>
    
  </CCardBody>
  <CCardBody style={{color:'#b3ffff'}}>
    <CCardTitle style={dggn}><GiPlayerTime/>Sports Science</CCardTitle>
    <CCardText style={{textAlign:'left',fontSize:'1.3em'}}> 
    The app is focused on collecting athlete specific details across number of modules which ultimately provides us with in depth analysis about the key parameters which lets us know where exactly the athletes perfomance is ranging  with the expected outcome.​

App allows various departments at any sporting facility inter- communicate  and refer athletes for further analysis this way none of the details and scheduled timelines are overlooked​

The prime goal is too keep the athlete conditioned to their prime capabilities and prevent /recover from injuries.​
<CCardBody style={{color:'#b3ffff'}}>
    <CCardTitle style={dgg}><MdOutlineViewModule/>Modules</CCardTitle>
    <CCardText style={{textAlign:'left',fontSize:'1.3em'}}> 
    <FaNutritionix/> Nutrition
   <br/>
   <TbPhysotherapist/>Physiotherapy ​
   <br/>
<GiHealthPotion />Physiology ​
<br/>
<RiPsychotherapyLine/>Psychology ​
<br/>
<VscBeaker />Biochemistry​
<br/>
<GiMechanicalArm/>Biomechanics​
<br/>
<GiSkeleton/>Anthropometry ​
<br/>
<TbMassage/>Massage Therapy​
<br/>
<CgGym/>Strength & Conditioning​
<br/>
<MdPersonalInjury/>Injury mangement​
    </CCardText>
    
  </CCardBody>
  <CCardBody style={{color:'#b3ffff'}}>
    <CCardTitle style={dgg}><MdOutlineHighQuality/>Sports talent identification App</CCardTitle>
    <CCardText style={{textAlign:'left',fontSize:'1.3em'}}> 
    This app is focused on identifying sporting talents in the country at a very young age .​

We collect athlete data on basis of some mandatory tests and some sports specific tests.​

Mandatory tests include endurance run , shuttle run,high jump,sit-ups etc​

Sports specific test include shortput throw for athletics,vertical jump for volleyball, zig zag run for football and hockey etc​

After collecting the data and running simulations we identify the best talent among age groups of six to eighteen.​

We can recommend which talent might be suited for which particular sport
    </CCardText>
    
  </CCardBody>
    </CCardText>
    
  </CCardBody>
  <CCardBody style={{color:'#b3ffff'}}>
    <CCardTitle style={dggn}><MdOutlineAppSettingsAlt/>Lifeline App</CCardTitle>
    <CCardText style={{textAlign:'left',fontSize:'1.3em'}}> 
    <FaChevronCircleRight/>Quick Care, Anytime, Anywhere
    <br/>
    <FaChevronCircleRight/>Emergency Help at Your Fingertips
    <br/>
    <FaChevronCircleRight/>Fast Track to Lifesaving Care
    <br/>
    <FaChevronCircleRight/>Your Health, Just a Tap Away
    <br/>
    <FaChevronCircleRight/>Urgent Care, Delivered On Demand
    <br/>
    <FaChevronCircleRight/>Seamless Hospital Booking, Swift Ambulance Service
    <br/>
    <FaChevronCircleRight/>Bringing Help Closer, Faster
    <br/>
    <FaChevronCircleRight/>App is integrated with CDDCS device dash cam
    <br/>
    </CCardText>
    
  </CCardBody>
</CCard>
</CCol>
</CRow>
</CContainer>
<CContainer fluid style={wlpr}>
<CRow>
<CCol lg={12} xl={12} xxl={12} style={{textAlign:'left',fonWeight:'500',fontFamily:'Merriweather',fontSize:'1.0em',color:'#b3ffff'}}>
<CCard style={{ width: '100%',padding:'1%',background:'linear-gradient(45deg, #0f0c29, #302b63, #24243e)' }}>
  <CCardImage orientation="top" style={ghj} />
  <CCardBody style={{color:'#b3ffff'}}>
    <CCardTitle style={dgg}><FcAbout/>About Us</CCardTitle>
    <CCardText style={{textAlign:'left',fontSize:'1.3em'}}> 
    <FaChevronCircleRight/> We at Hindcare Medtech our dedicated to develop technology based innovation which provide significant prospects towards sustainable and holistic environment for society​.
   <br/>
   <FaChevronCircleRight/> Our prime projects our developing sports based technologies which our aimed to nurture athletes and help them perform to best of their capabilities. 
   <br/>
   <FaChevronCircleRight/> Our generative AI based Iot solutions look forward to take care of athletes nutrition ,recovery from injury and identifying budding sporting talents in the country at a very young age .
    <br/>
    <FaChevronCircleRight/> We take pride in researching and developing cutting edge medical technology.
    <br/>
    <FaChevronCircleRight/> AI powered dash cam with crash-detection system for auotmatic ambulance booking.
    <br/>
    <FaChevronCircleRight/> Smart bio medical garbage waste management,helping hospitals to segregate waste.
    <br/>
    <FaChevronCircleRight/> Lifeline Ambulance & Hospital Booking App  with real time tracking.
    <br/>
     <FaChevronCircleRight/> Hindcare medtech private limited is a startup enterprise working since july,2023 to 
cultivate synergy between technology, healthcare and sports. 
We our incubated with STPI, Medtech, SGPGI, Lucknow. 
    </CCardText>
    
  </CCardBody>
</CCard>
</CCol>
</CRow>
</CContainer>
<CContainer fluid style={wlpr}>
<CImage fluid rounded src="http://localhost/HindCare/Image/team.png" />
</CContainer>
<CContainer fluid style={wlpr}>
    <CRow>
    <CCol lg={12} xl={12} xxl={12}>
    <CImage fluid rounded src="http://localhost/HindCare/Image/certificate.png" />
    </CCol>
    </CRow>
    </CContainer>
      <CContainer fluid style={ftr}>
      <CRow>
      <CCol lg={12} xl={12} xxl={12}>
      <CLink href="https://coreui.io">@ Copyright <span id="cprt_yr"></span></CLink>
    <span>&copy;HindCare Medtech Pvt Ltd.</span>
 
    <span >&nbsp;All rights reserved.</span>
      </CCol>
     </CRow>
</CContainer>
         </>
          )}
          {isTabletOrMobile && (
               <>
                <CContainer fluid style={hdr_tab}>
          <CRow>
              <CCol sm={3} md={3} lg={3} style={hdr_img}>
              <CImage fluid rounded src="https://lifelinehealth.in/bmw/logo1.png" width={'40em'} height={'40em'} />
              </CCol>
              <CCol sm={6} md={6} lg={6}>
              <CRow>
              <CCol sm={3} md={3} lg={3} style={tg_ln1}>
              Empowering Health Through Technology.
              </CCol>
              <CCol sm={6} md={6} lg={6} style={cmpny_stl1}>
                HINDCARE MEDTEC PVT. LTD.
              </CCol>
              <CCol sm={3} md={3} lg={3} style={tg_ln1}>
              Smart Tech, Smarter Healthcare.
              </CCol>
              </CRow>
              </CCol>
              <CCol sm={3} md={3} lg={3} style={hdr_sochl_otr}>
                <CRow>
                <CCol sm={3} md={3} lg={3} style={hdr_sochl1}><TiSocialInstagramCircular/></CCol>
                <CCol sm={3} md={3} lg={3} style={hdr_sochl1}><TiSocialLinkedinCircular/></CCol>
                <CCol sm={3} md={3} lg={3} style={hdr_sochl1}><RiTwitterXFill/></CCol>
                <CCol sm={3} md={3} lg={3} style={hdr_sochl1}><RiYoutubeLine /></CCol>
                </CRow>
              </CCol>
          </CRow>
         </CContainer>
         <CContainer fluid style={wlpr}>
         <CCarousel controls transition="crossfade">
  <CCarouselItem>
  <CRow>
  <CCol sm={12} md={12} lg={12}>
    <CImage fluid rounded className="d-inline w-100" style={wlppr1} src={"http://localhost/HindCare/Image/ambulance.png"} alt="Ambulance" />
    </CCol>
    <CCol sm={12} md={12} lg={12} style={{textAlign:'left',paddingTop:'12%',fonWeight:'600',fontFamily:'Montserrat',fontSize:'0.85em'}}>
    <CWidgetStatsF style={{background:'linear-gradient(45deg, #0d0d0d, #2a0038, #2a0038,  #0d0d0d)',color:'#b3ffff'}}
        className="mb-3"
        value="Lifeline App to Book ambulance and hospital beds through our extensive network"
       />

         </CCol>
         </CRow>
  </CCarouselItem>
  <CCarouselItem>
  <CRow>
  <CCol sm={12} md={12} lg={12}>
    <CImage fluid rounded className="d-inline w-100"  style={wlppr1}   src={"http://localhost/HindCare/Image/smart_bin.jpg"} alt="garbage_bin" />
    </CCol>
    <CCol sm={12} md={12} lg={12} style={{textAlign:'left',paddingTop:'12%',fonWeight:'600',fontFamily:'Montserrat',fontSize:'0.85em'}}>
    <CWidgetStatsF style={{background:'linear-gradient(45deg, #0d0d0d, #2a0038, #2a0038,  #0d0d0d)',color:'#b3ffff'}}
        className="mb-3"
        value="BMWSGB(Bio Medical Waste Smart Garbage Bin)"
       />
         </CCol>
    </CRow>
  </CCarouselItem>
  <CCarouselItem>
  <CRow>
  <CCol  sm={12} md={12} lg={12}>
    <CImage fluid rounded className="d-inline w-100"   style={wlppr1}   src={"http://localhost/HindCare/Image/dash.jfif"} alt="garbage_bin" />
    </CCol>
    <CCol sm={12} md={12} lg={12} style={{textAlign:'left',paddingTop:'12%',fonWeight:'600',fontFamily:'Montserrat',fontSize:'0.85em'}}>
    <CWidgetStatsF style={{background:'linear-gradient(45deg, #0d0d0d, #2a0038, #2a0038,  #0d0d0d)',color:'#b3ffff'}}
        className="mb-3"
        value="AI powered Dash Cam device with crash detection feature(CDDCS)"
       />
         </CCol>
    </CRow>
  </CCarouselItem>
  <CCarouselItem>
  <CRow>
  <CCol  sm={12} md={12} lg={12}>
    <CImage fluid rounded className="d-inline w-100"  style={wlppr1}   src={"http://localhost/HindCare/Image/sports.png"} alt="garbage_bin" />
    </CCol>
    <CCol  sm={12} md={12} lg={12} style={{textAlign:'left',paddingTop:'12%',fonWeight:'600',fontFamily:'Montserrat',fontSize:'0.85em'}}>
    <CWidgetStatsF style={{background:'linear-gradient(45deg, #0d0d0d, #2a0038, #2a0038,  #0d0d0d)',color:'#b3ffff'}}
        className="mb-3"
        value="Sports Science(In depth analysis about the key athletic parameters)"
       />
         </CCol>
    </CRow>
  </CCarouselItem>

</CCarousel>

</CContainer>
<CContainer fluid style={wlpr}>
<CRow>
<CCol sm={12} md={12} lg={12} style={{textAlign:'left',fonWeight:'500',fontFamily:'Merriweather',fontSize:'1.0em',color:'#b3ffff'}}>
<CCard style={{ width: '100%',padding:'1%',background:'linear-gradient(45deg, #0f0c29, #302b63, #24243e)' }}>
  <CCardImage orientation="top" style={ghj} />
  <CCardBody style={{color:'#b3ffff'}}>
    <CCardTitle style={dgg}><BsFillProjectorFill/>Projects</CCardTitle>
    <CCardText style={{textAlign:'left',fontSize:'1.3em',fontStyle:'italic'}}> 
     Here are some details into our projects!!
    </CCardText>
    
  </CCardBody>
  <CCardBody style={{color:'#b3ffff'}}>
    <CCardTitle style={dggn}><SiGooglelens/>CDDCS</CCardTitle>
    <CCardText style={{textAlign:'left',fontSize:'1.3em'}}> 
    CDDCS(Crash Detection Dash Cam System) :-for automobiles which will enable any 
user with the  device installed on their vehicle will have access to an ambulance in case 
the vehicle meets with any accident without  any manual intervention. 
Currently we have developed the crash detection prototype and in process of designing a 
3d model for testing the device on a ramp. 
Also the camera is AI trained to detect the degree of emergency required in case of  
accident , the inormation and image can be used to synchronize ambulance, hospitals to  
handle the case in  most efficient way.
<br/>
Heres a snapshot of our device data
<br/>
<CImage fluid rounded src="http://localhost/HindCare/Image/cdds.png" />
   <br/>
   Once the prototype meets the industry standards, we will convert the device into a <VscCircuitBoard/>(PCB).
    </CCardText>
    
  </CCardBody>
  <CCardBody style={{color:'#b3ffff'}}>
    <CCardTitle style={dggn}><FaTrashAlt/>BMWSGB</CCardTitle>
    <CCardText style={{textAlign:'center',fontSize:'1.3em'}}> 
    BMWSGB (Bio Medical Waste Smart Garbage Bin) :-  The medical facilities have a big 
challenge of segregating medical waste. On an average  a 100 bedded hospital generates 
100-200kg of  waste per day.To tackle this problem we our developing a smart BMW  
garbage bin 
Our team is  training  the system  currently on various sample of medical waste using 
image recognition ,the process of training  the system can take 4-8months,since the list of 
medical waste is huge. 
The physical  design of the  bin is also being explored as well simultaneously .
<br/>
<CImage fluid rounded src="http://localhost/HindCare/Image/bmw.png" />
    </CCardText>
    
  </CCardBody>
  <CCardBody style={{color:'#b3ffff'}}>
    <CCardTitle style={dggn}><GiPlayerTime/>Sports Science</CCardTitle>
    <CCardText style={{textAlign:'left',fontSize:'1.3em'}}> 
    The app is focused on collecting athlete specific details across number of modules which ultimately provides us with in depth analysis about the key parameters which lets us know where exactly the athletes perfomance is ranging  with the expected outcome.​

App allows various departments at any sporting facility inter- communicate  and refer athletes for further analysis this way none of the details and scheduled timelines are overlooked​

The prime goal is too keep the athlete conditioned to their prime capabilities and prevent /recover from injuries.​
<CCardBody style={{color:'#b3ffff'}}>
    <CCardTitle style={dgg}><MdOutlineViewModule/>Modules</CCardTitle>
    <CCardText style={{textAlign:'left',fontSize:'1.3em'}}> 
    <FaNutritionix/> Nutrition
   <br/>
   <TbPhysotherapist/>Physiotherapy ​
   <br/>
<GiHealthPotion />Physiology ​
<br/>
<RiPsychotherapyLine/>Psychology ​
<br/>
<VscBeaker />Biochemistry​
<br/>
<GiMechanicalArm/>Biomechanics​
<br/>
<GiSkeleton/>Anthropometry ​
<br/>
<TbMassage/>Massage Therapy​
<br/>
<CgGym/>Strength & Conditioning​
<br/>
<MdPersonalInjury/>Injury mangement​
    </CCardText>
    
  </CCardBody>
  <CCardBody style={{color:'#b3ffff'}}>
    <CCardTitle style={dgg}><MdOutlineHighQuality/>Sports talent identification App</CCardTitle>
    <CCardText style={{textAlign:'left',fontSize:'1.3em'}}> 
    This app is focused on identifying sporting talents in the country at a very young age .​

We collect athlete data on basis of some mandatory tests and some sports specific tests.​

Mandatory tests include endurance run , shuttle run,high jump,sit-ups etc​

Sports specific test include shortput throw for athletics,vertical jump for volleyball, zig zag run for football and hockey etc​

After collecting the data and running simulations we identify the best talent among age groups of six to eighteen.​

We can recommend which talent might be suited for which particular sport
    </CCardText>
    
  </CCardBody>
    </CCardText>
    
  </CCardBody>
  <CCardBody style={{color:'#b3ffff'}}>
    <CCardTitle style={dggn}><MdOutlineAppSettingsAlt/>Lifeline App</CCardTitle>
    <CCardText style={{textAlign:'left',fontSize:'1.3em'}}> 
    <FaChevronCircleRight/>Quick Care, Anytime, Anywhere
    <br/>
    <FaChevronCircleRight/>Emergency Help at Your Fingertips
    <br/>
    <FaChevronCircleRight/>Fast Track to Lifesaving Care
    <br/>
    <FaChevronCircleRight/>Your Health, Just a Tap Away
    <br/>
    <FaChevronCircleRight/>Urgent Care, Delivered On Demand
    <br/>
    <FaChevronCircleRight/>Seamless Hospital Booking, Swift Ambulance Service
    <br/>
    <FaChevronCircleRight/>Bringing Help Closer, Faster
    <br/>
    <FaChevronCircleRight/>App is integrated with CDDCS device dash cam
    <br/>
    </CCardText>
    
  </CCardBody>
</CCard>
</CCol>
</CRow>
</CContainer>
<CContainer fluid style={wlpr}>
<CRow>
<CCol sm={12} md={12} lg={12} style={{textAlign:'left',fonWeight:'500',fontFamily:'Merriweather',fontSize:'1.0em',color:'#b3ffff'}}>
<CCard style={{ width: '100%',padding:'1%',background:'linear-gradient(45deg, #0f0c29, #302b63, #24243e)' }}>
  <CCardImage orientation="top" style={ghj} />
  <CCardBody style={{color:'#b3ffff'}}>
    <CCardTitle style={dgg}><FcAbout/>About Us</CCardTitle>
    <CCardText style={{textAlign:'left',fontSize:'1.3em'}}> 
    <FaChevronCircleRight/> We at Hindcare Medtech our dedicated to develop technology based innovation which provide significant prospects towards sustainable and holistic environment for society​.
   <br/>
   <FaChevronCircleRight/> Our prime projects our developing sports based technologies which our aimed to nurture athletes and help them perform to best of their capabilities. 
   <br/>
   <FaChevronCircleRight/> Our generative AI based Iot solutions look forward to take care of athletes nutrition ,recovery from injury and identifying budding sporting talents in the country at a very young age .
    <br/>
    <FaChevronCircleRight/> We take pride in researching and developing cutting edge medical technology.
    <br/>
    <FaChevronCircleRight/> AI powered dash cam with crash-detection system for auotmatic ambulance booking.
    <br/>
    <FaChevronCircleRight/> Smart bio medical garbage waste management,helping hospitals to segregate waste.
    <br/>
    <FaChevronCircleRight/> Lifeline Ambulance & Hospital Booking App  with real time tracking.
    <br/>
     <FaChevronCircleRight/> Hindcare medtech private limited is a startup enterprise working since july,2023 to 
cultivate synergy between technology, healthcare and sports. 
We our incubated with STPI, Medtech, SGPGI, Lucknow. 
    </CCardText>
    
  </CCardBody>
</CCard>
</CCol>
</CRow>
</CContainer>
<CContainer fluid style={wlpr}>
<CImage fluid rounded src="http://localhost/HindCare/Image/team.png" />
</CContainer>
<CContainer fluid style={wlpr}>
    <CRow>
    <CCol sm={12} md={12} lg={12}>
    <CImage fluid rounded src="http://localhost/HindCare/Image/certificate.png" />
    </CCol>
    </CRow>
    </CContainer>
      <CContainer fluid style={ftr}>
      <CRow>
      <CCol sm={12} md={12} lg={12}>
      <CLink href="https://coreui.io">@ Copyright <span id="cprt_yr"></span></CLink>
    <span>&copy;HindCare Medtech Pvt Ltd.</span>
 
    <span >&nbsp;All rights reserved.</span>
      </CCol>
     </CRow>
</CContainer>
               </>
              )}
              {isMobile && (
                   <>
                    <CContainer fluid style={hdr_mob}>
          <CRow>
              <CCol xs={12} style={hdr_img}>
              <CImage fluid rounded src="https://lifelinehealth.in/bmw/logo1.png" width={'40em'} height={'40em'} />
              <h3 style={cmpny_stl}>HINDCARE MEDTEC PVT. LTD.</h3>
            <span  style={tg_lnz}>Empowering Health Through Technology.</span>
              
              </CCol>
             
          </CRow>
         </CContainer>
         <CContainer fluid style={wlpr}>
         <CCarousel controls transition="crossfade">
  <CCarouselItem>
  <CRow>
  <CCol sm={12} md={12} lg={12}>
    <CImage fluid rounded className="d-inline w-100" style={wlppr1} src={"http://localhost/HindCare/Image/ambulance.png"} alt="Ambulance" />
    </CCol>
    <CCol sm={12} md={12} lg={12} style={{textAlign:'left',paddingTop:'12%',fonWeight:'600',fontFamily:'Montserrat',fontSize:'0.85em'}}>
    <CWidgetStatsF style={{background:'linear-gradient(45deg, #0d0d0d, #2a0038, #2a0038,  #0d0d0d)',color:'#b3ffff'}}
        className="mb-3"
        value="Lifeline App to Book ambulance and hospital beds through our extensive network"
       />

         </CCol>
         </CRow>
  </CCarouselItem>
  <CCarouselItem>
  <CRow>
  <CCol sm={12} md={12} lg={12}>
    <CImage fluid rounded className="d-inline w-100"  style={wlppr1}   src={"http://localhost/HindCare/Image/smart_bin.jpg"} alt="garbage_bin" />
    </CCol>
    <CCol sm={12} md={12} lg={12} style={{textAlign:'left',paddingTop:'12%',fonWeight:'600',fontFamily:'Montserrat',fontSize:'0.85em'}}>
    <CWidgetStatsF style={{background:'linear-gradient(45deg, #0d0d0d, #2a0038, #2a0038,  #0d0d0d)',color:'#b3ffff'}}
        className="mb-3"
        value="BMWSGB(Bio Medical Waste Smart Garbage Bin)"
       />
         </CCol>
    </CRow>
  </CCarouselItem>
  <CCarouselItem>
  <CRow>
  <CCol  sm={12} md={12} lg={12}>
    <CImage fluid rounded className="d-inline w-100"   style={wlppr1}   src={"http://localhost/HindCare/Image/dash.jfif"} alt="garbage_bin" />
    </CCol>
    <CCol sm={12} md={12} lg={12} style={{textAlign:'left',paddingTop:'12%',fonWeight:'600',fontFamily:'Montserrat',fontSize:'0.85em'}}>
    <CWidgetStatsF style={{background:'linear-gradient(45deg, #0d0d0d, #2a0038, #2a0038,  #0d0d0d)',color:'#b3ffff'}}
        className="mb-3"
        value="AI powered Dash Cam device with crash detection feature(CDDCS)"
       />
         </CCol>
    </CRow>
  </CCarouselItem>
  <CCarouselItem>
  <CRow>
  <CCol  sm={12} md={12} lg={12}>
    <CImage fluid rounded className="d-inline w-100"  style={wlppr1}   src={"http://localhost/HindCare/Image/sports.png"} alt="garbage_bin" />
    </CCol>
    <CCol  sm={12} md={12} lg={12} style={{textAlign:'left',paddingTop:'12%',fonWeight:'600',fontFamily:'Montserrat',fontSize:'0.85em'}}>
    <CWidgetStatsF style={{background:'linear-gradient(45deg, #0d0d0d, #2a0038, #2a0038,  #0d0d0d)',color:'#b3ffff'}}
        className="mb-3"
        value="Sports Science(In depth analysis about the key athletic parameters)"
       />
         </CCol>
    </CRow>
  </CCarouselItem>

</CCarousel>

</CContainer>
<CContainer fluid style={wlpr}>
<CRow>
<CCol sm={12} md={12} lg={12} style={{textAlign:'left',fonWeight:'500',fontFamily:'Merriweather',fontSize:'1.0em',color:'#b3ffff'}}>
<CCard style={{ width: '100%',padding:'1%',background:'linear-gradient(45deg, #0f0c29, #302b63, #24243e)' }}>
  <CCardImage orientation="top" style={ghj} />
  <CCardBody style={{color:'#b3ffff'}}>
    <CCardTitle style={dgg}><BsFillProjectorFill/>Projects</CCardTitle>
    <CCardText style={{textAlign:'left',fontSize:'1.3em',fontStyle:'italic'}}> 
     Here are some details into our projects!!
    </CCardText>
    
  </CCardBody>
  <CCardBody style={{color:'#b3ffff'}}>
    <CCardTitle style={dggn}><SiGooglelens/>CDDCS</CCardTitle>
    <CCardText style={{textAlign:'left',fontSize:'1.3em'}}> 
    CDDCS(Crash Detection Dash Cam System) :-for automobiles which will enable any 
user with the  device installed on their vehicle will have access to an ambulance in case 
the vehicle meets with any accident without  any manual intervention. 
Currently we have developed the crash detection prototype and in process of designing a 
3d model for testing the device on a ramp. 
Also the camera is AI trained to detect the degree of emergency required in case of  
accident , the inormation and image can be used to synchronize ambulance, hospitals to  
handle the case in  most efficient way.
<br/>
Heres a snapshot of our device data
<br/>
<CImage fluid rounded src="http://localhost/HindCare/Image/cdds.png" />
   <br/>
   Once the prototype meets the industry standards, we will convert the device into a <VscCircuitBoard/>(PCB).
    </CCardText>
    
  </CCardBody>
  <CCardBody style={{color:'#b3ffff'}}>
    <CCardTitle style={dggn}><FaTrashAlt/>BMWSGB</CCardTitle>
    <CCardText style={{textAlign:'center',fontSize:'1.3em'}}> 
    BMWSGB (Bio Medical Waste Smart Garbage Bin) :-  The medical facilities have a big 
challenge of segregating medical waste. On an average  a 100 bedded hospital generates 
100-200kg of  waste per day.To tackle this problem we our developing a smart BMW  
garbage bin 
Our team is  training  the system  currently on various sample of medical waste using 
image recognition ,the process of training  the system can take 4-8months,since the list of 
medical waste is huge. 
The physical  design of the  bin is also being explored as well simultaneously .
<br/>
<CImage fluid rounded src="http://localhost/HindCare/Image/bmw.png" />
    </CCardText>
    
  </CCardBody>
  <CCardBody style={{color:'#b3ffff'}}>
    <CCardTitle style={dggn}><GiPlayerTime/>Sports Science</CCardTitle>
    <CCardText style={{textAlign:'left',fontSize:'1.3em'}}> 
    The app is focused on collecting athlete specific details across number of modules which ultimately provides us with in depth analysis about the key parameters which lets us know where exactly the athletes perfomance is ranging  with the expected outcome.​

App allows various departments at any sporting facility inter- communicate  and refer athletes for further analysis this way none of the details and scheduled timelines are overlooked​

The prime goal is too keep the athlete conditioned to their prime capabilities and prevent /recover from injuries.​
<CCardBody style={{color:'#b3ffff'}}>
    <CCardTitle style={dgg}><MdOutlineViewModule/>Modules</CCardTitle>
    <CCardText style={{textAlign:'left',fontSize:'1.3em'}}> 
    <FaNutritionix/> Nutrition
   <br/>
   <TbPhysotherapist/>Physiotherapy ​
   <br/>
<GiHealthPotion />Physiology ​
<br/>
<RiPsychotherapyLine/>Psychology ​
<br/>
<VscBeaker />Biochemistry​
<br/>
<GiMechanicalArm/>Biomechanics​
<br/>
<GiSkeleton/>Anthropometry ​
<br/>
<TbMassage/>Massage Therapy​
<br/>
<CgGym/>Strength & Conditioning​
<br/>
<MdPersonalInjury/>Injury mangement​
    </CCardText>
    
  </CCardBody>
  <CCardBody style={{color:'#b3ffff'}}>
    <CCardTitle style={dgg}><MdOutlineHighQuality/>Sports talent identification App</CCardTitle>
    <CCardText style={{textAlign:'left',fontSize:'1.3em'}}> 
    This app is focused on identifying sporting talents in the country at a very young age .​

We collect athlete data on basis of some mandatory tests and some sports specific tests.​

Mandatory tests include endurance run , shuttle run,high jump,sit-ups etc​

Sports specific test include shortput throw for athletics,vertical jump for volleyball, zig zag run for football and hockey etc​

After collecting the data and running simulations we identify the best talent among age groups of six to eighteen.​

We can recommend which talent might be suited for which particular sport
    </CCardText>
    
  </CCardBody>
    </CCardText>
    
  </CCardBody>
  <CCardBody style={{color:'#b3ffff'}}>
    <CCardTitle style={dggn}><MdOutlineAppSettingsAlt/>Lifeline App</CCardTitle>
    <CCardText style={{textAlign:'left',fontSize:'1.3em'}}> 
    <FaChevronCircleRight/>Quick Care, Anytime, Anywhere
    <br/>
    <FaChevronCircleRight/>Emergency Help at Your Fingertips
    <br/>
    <FaChevronCircleRight/>Fast Track to Lifesaving Care
    <br/>
    <FaChevronCircleRight/>Your Health, Just a Tap Away
    <br/>
    <FaChevronCircleRight/>Urgent Care, Delivered On Demand
    <br/>
    <FaChevronCircleRight/>Seamless Hospital Booking, Swift Ambulance Service
    <br/>
    <FaChevronCircleRight/>Bringing Help Closer, Faster
    <br/>
    <FaChevronCircleRight/>App is integrated with CDDCS device dash cam
    <br/>
    </CCardText>
    
  </CCardBody>
</CCard>
</CCol>
</CRow>
</CContainer>
<CContainer fluid style={wlpr}>
<CRow>
<CCol sm={12} md={12} lg={12} style={{textAlign:'left',fonWeight:'500',fontFamily:'Merriweather',fontSize:'1.0em',color:'#b3ffff'}}>
<CCard style={{ width: '100%',padding:'1%',background:'linear-gradient(45deg, #0f0c29, #302b63, #24243e)' }}>
  <CCardImage orientation="top" style={ghj} />
  <CCardBody style={{color:'#b3ffff'}}>
    <CCardTitle style={dgg}><FcAbout/>About Us</CCardTitle>
    <CCardText style={{textAlign:'left',fontSize:'1.3em'}}> 
    <FaChevronCircleRight/> We at Hindcare Medtech our dedicated to develop technology based innovation which provide significant prospects towards sustainable and holistic environment for society​.
   <br/>
   <FaChevronCircleRight/> Our prime projects our developing sports based technologies which our aimed to nurture athletes and help them perform to best of their capabilities. 
   <br/>
   <FaChevronCircleRight/> Our generative AI based Iot solutions look forward to take care of athletes nutrition ,recovery from injury and identifying budding sporting talents in the country at a very young age .
    <br/>
    <FaChevronCircleRight/> We take pride in researching and developing cutting edge medical technology.
    <br/>
    <FaChevronCircleRight/> AI powered dash cam with crash-detection system for auotmatic ambulance booking.
    <br/>
    <FaChevronCircleRight/> Smart bio medical garbage waste management,helping hospitals to segregate waste.
    <br/>
    <FaChevronCircleRight/> Lifeline Ambulance & Hospital Booking App  with real time tracking.
    <br/>
     <FaChevronCircleRight/> Hindcare medtech private limited is a startup enterprise working since july,2023 to 
cultivate synergy between technology, healthcare and sports. 
We our incubated with STPI, Medtech, SGPGI, Lucknow. 
    </CCardText>
    
  </CCardBody>
</CCard>
</CCol>
</CRow>
</CContainer>
<CContainer fluid style={wlpr}>
<CImage fluid rounded src="http://localhost/HindCare/Image/team.png" />
</CContainer>
<CContainer fluid style={wlpr}>
    <CRow>
    <CCol sm={12} md={12} lg={12}>
    <CImage fluid rounded src="http://localhost/HindCare/Image/certificate.png" />
    </CCol>
    </CRow>
    </CContainer>
      <CContainer fluid style={ftr}>
      <CRow>
      <CCol sm={12} md={12} lg={12}>
      <CLink href="https://coreui.io">@ Copyright <span id="cprt_yr"></span></CLink>
    <span>&copy;HindCare Medtech Pvt Ltd.</span>
 
    <span >&nbsp;All rights reserved.</span>
      </CCol>
     </CRow>
</CContainer>
         
                   </>
              )}
              </div>
    );
}

export default Home;