import logo from './logo.svg';
import './App.css';
import { useMediaQuery } from 'react-responsive';
import React from "react";
import Home from "./Home";
import { Route, Routes,HashRouter as Router } from 'react-router-dom';
function App() {
  const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 999px)'});
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 998.9px) and (min-width:600px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 599px)' });
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });   
  return (
    <div className="App">
        {isDesktopOrLaptop && (
         <>
              <Router>
                    <Routes>
                     <Route path='/' element={<Home/>} />
                     
                    </Routes>
                </Router>
         </>
        )}
          {isTabletOrMobile && (
         <>
         <Router>
                    <Routes>
                     <Route path='/' element={<Home/>} />
                     
                    </Routes>
          </Router>
         </>
        )}
        {isMobile && (
        <>
            <Router>
                    <Routes>
                     <Route path='/' element={<Home/>} />
                     
                    </Routes>
                </Router>
         </>
           )}      
    </div>
  );
}

export default App;
